import { useAnalyticsTitle } from "@/hooks/useAnalyticsTitle";
import { Container, Typography } from "@mui/material";
import { useEffect } from "react";
import {
  useLocation,
  useNavigate,
  useOutlet,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { Helmet } from "react-helmet-async";
import pageContent from "@/constants/pageContent";
import { DEFAULT_STATE } from "@/constants/states";
import { useDispatch } from "react-redux";
import {
  showSelectedStateModal,
  toggleSelectedStateModal,
} from "@/app/features/userState/userStateSlice";

const OfferPage = () => {
  useAnalyticsTitle(pageContent.offer.title);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const outlet = useOutlet();
  const { state: stateChoiceFromUrl } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (!stateChoiceFromUrl) {
      const persistedStateChoice = localStorage.getItem("state");
      if (persistedStateChoice) {
        navigate(
          `/offer-tracker/state/${persistedStateChoice}?&make=All&offer=Current`
        );
      }
      // else {
      //   navigate(
      //     `/offer-tracker/state/${DEFAULT_STATE}?&make=All&offer=Current`
      //   );
      // }
      else {
        dispatch(showSelectedStateModal());
      }
    }
  }, [location, searchParams, stateChoiceFromUrl, navigate]);

  return (
    <Container
      maxWidth="lg"
      sx={{
        paddingBottom: "50px",
      }}
    >
      <Helmet>
        <title>{pageContent.offer.title}</title>
        {pageContent.offer.description && (
          <meta name="description" content={pageContent.offer.description} />
        )}
      </Helmet>
      <Typography
        component="h1"
        textAlign="center"
        sx={{
          fontWeight: 400,
          fontSize: {
            xs: "16px",
            md: "16px",
          },
          lineHeight: "123.5%",
          mt: {
            xs: "16px",
            md: "40px",
          },
          mb: {
            xs: "16px",
            md: "24px",
          },

          "& b": {
            fontWeight: 600,
          },
        }}
      >
        Search for the best deals with our free and extensive Australian
        automotive offers database. Explore current and past vehicle offers,
        promotions and sales campaigns across all major car brands.
      </Typography>

      {/* <StyledAdImage src={AdImage} alt="add" /> */}
      {/* <StyledAdDiv id="native-ad" /> */}

      {/*  <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-4815933287091578"
        data-ad-slot="6620542490"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins> */}

      {/* <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-4815933287091578"
        data-ad-slot="4892832745"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins> */}

      {/* {state ? <SelectOfferForm /> : <DefaultOfferForm />} */}

      {outlet}

      {/* 
      <StyledAdImage2 src={AdImage} alt="add" /> */}
    </Container>
  );
};

export default OfferPage;

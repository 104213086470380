import React from "react";
import { Link } from "react-router-dom";
import { Box, Container, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ReactComponent as Facebook } from "@/assets/icons/facebook.svg";
import { ReactComponent as Instagram } from "@/assets/icons/instagram.svg";
import { ReactComponent as TikTok } from "@/assets/icons/tiktok.svg";
import { ReactComponent as LinkedIn } from "@/assets/icons/linkedin.svg";

import NewsletterSubscribe from "./NewsletterSubscribe";

const CopyrightText = styled("p")(({ theme }) => ({
  textAlign: "left",
  // margin: 0,
  padding: 0,
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "21px",
  letterSpacing: "0.005em",
  color: "#fff",
  textDecoration: "none",
}));

const FooterLink = styled(Link)(() => ({
  // fontSize: "13px",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "21px",
  letterSpacing: "0.005em",
  color: "#fff",
  textDecoration: "none",
}));

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: (theme) => theme.palette.neutral["1000"],
        color: "#fff",
        p: { xs: "40px 0px", md: "56px 0px" },
      }}
    >
      <Container
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: { xs: "flex-start", md: "flex-start" },
          flexDirection: { xs: "column", md: "row" },
          width: { xs: "100%", md: "90%", lg: "93%" },
          gap: { xs: "0rem", md: "16px", lg: "13rem" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            flexBasis: 0,
            // rowGap: { xs: "16px", md: "16px", lg: "40px" },
            marginBottom: { xs: "40px", md: "0px" },
            width: { xs: "100%", md: "50%" },
          }}
        >
          {/* <HubspotContactForm /> */}
          <NewsletterSubscribe />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            flexBasis: 0,
            width: { xs: "100%", md: "80%" },
            display: "flex",
            justifyContent: "center",
            alignItems: { xs: "flex-start", md: "flex-end" },
            flexDirection: { xs: "column" },
          }}
        >
          <Stack direction="row" gap="50px" mb="40px">
            <a
              target="_blank"
              rel="noreferrer"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              href="https://www.instagram.com/thebeep.com.au/#"
            >
              <Instagram
                style={{
                  width: "40px",
                  height: "40px",
                }}
              />
            </a>
            <a
              target="_blank"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              rel="noreferrer"
              href="https://www.facebook.com/thebeepau"
            >
              <Facebook
                style={{
                  width: "40px",
                  height: "40px",
                }}
              />
            </a>

            <a
              target="_blank"
              rel="noreferrer"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              href="https://www.tiktok.com/@thebeepau"
            >
              <TikTok
                style={{
                  width: "40px",
                  height: "40px",
                }}
              />
            </a>

            <a
              target="_blank"
              rel="noreferrer"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              href="https://www.linkedin.com/company/thebeep"
            >
              <LinkedIn
                style={{
                  width: "40px",
                  height: "40px",
                }}
              />
            </a>
          </Stack>

          <Stack direction="row" gap="32px" mb="16px">
            <FooterLink to="/privacy/state">Privacy Policy</FooterLink>
            <FooterLink to="/terms/state">Terms of Use</FooterLink>
          </Stack>
          <CopyrightText>
            Copyright © {new Date().getFullYear()} | The Beep Australia Pty Ltd
          </CopyrightText>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;

import { useState } from "react";
import { styled } from "@mui/material/styles";
import { Box, Button } from "@mui/material";

// const Input = styled("input")(({ theme }) => ({
//   width: "100%",
//   padding: "0px 11px",
//   borderRadius: "10px",
//   height: "40px",
//   fontSize: "16px",
//   fontWeight: 275,
//   outline: "none",
//   border: "none",
//   flex: "75%",
//   lineHeight: "32px",
//   ":placeholder": {
//     lineHeight: "32px",
//     letterSpacing: "0.08px",
//     color: "#82878C",
//   },
// }));

const Input = styled("input")(({ theme }) => ({
  padding: "4px 11px",
  borderRadius: "10px",
  fontSize: "16px",
  fontWeight: "400",
  outline: "none",
  border: "none",
  flex: "75%",
  lineHeight: "32px",

  ":placeholder": {
    lineHeight: "32px",
    letterSpacing: "0.08px",
    color: "#82878C",
  },
}));

const SubmitBtn = styled(Button)(({ theme }) => ({
  padding: "4px 20px",
  // height: "40px",
  width: "94px",
  borderRadius: "10px",
  fontSize: "16px",
  letterSpacing: "0.16px",
  textAlign: "center",
  fontWeight: 400,
  color: "#FFFFFF",
  background: "#4A98D3",
  outline: "none",
  lineHeight: "32px",

  ":hover": {
    border: "1px solid #82878c",
    color: "#FFFFFF",
    background: "#051019",
  },
  ":disabled": {
    color: "#FFFFFF",
    background: "#82878C",
  },
}));

const ErrorText = styled("span")(({ theme }) => ({
  width: "100%",
  fontSize: "13px",
  fontStyle: "italic",
  fontWeight: 300,
  lineHeight: "160%",
  letterSpacing: "0.065px",
  color: "#EC4D3F",
}));

const SuccessText = styled("span")(({ theme }) => ({
  width: "100%",
  fontSize: "13px",
  fontStyle: "italic",
  fontWeight: 300,
  lineHeight: "160%",
  letterSpacing: "0.065px",
  color: "#A9E5BB",
}));

const NewsletterText = styled("p")(({ theme }) => ({
  fontSize: "16px",
  textAlign: "left",
  margin: "0px",
  fontWeight: 400,
  lineHeight: "32px",
  letterSpacing: "0.005em",
  color: "#fff",
}));

const NewsletterForm = ({ status, message, onValidated }) => {
  const [error, setError] = useState(null);
  const [email, setEmail] = useState(null);

  /**
   * Handle form submit.
   *
   * @return {{value}|*|boolean|null}
   */
  const handleFormSubmit = () => {
    setError(null);

    if (!email) {
      setError("Please enter a valid email address");
      return null;
    }

    const isFormValidated = onValidated({ EMAIL: email });

    // On success return true
    return email && email.indexOf("@") > -1 && isFormValidated;
  };

  /**
   * Handle Input Key Event.
   *
   * @param event
   */
  const handleInputKeyEvent = (event) => {
    setError(null);
    // Number 13 is the "Enter" key on the keyboard
    if (event.keyCode === 13) {
      // Cancel the default action, if needed
      event.preventDefault();
      // Trigger the button element with a click
      handleFormSubmit();
    }
  };

  /**
   * Extract message from string.
   *
   * @param {String} message
   * @return {null|*}
   */
  const getMessage = (message) => {
    if (!message) {
      return null;
    }
    const result = message?.split("-") ?? null;
    if ("0" !== result?.[0]?.trim()) {
      //   return sanitize(message);
      return message;
    }
    const formattedMessage = result?.[1]?.trim() ?? null;
    return formattedMessage ? formattedMessage : null;
  };

  return (
    <>
      <NewsletterText
        style={{ fontSize: "16px", textAlign: "left" }}
        sx={{
          marginBottom: { xs: "16px", md: "40px" },
        }}
      >
        Stay in the loop and sign up for our newsletter including exclusive
        offers from The Beep
      </NewsletterText>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },

          rowGap: { xs: "8px", sm: "0px" },
          columnGap: { xs: "0px", sm: "8px" },
        }}
      >
        <Input
          onChange={(event) => setEmail(event?.target?.value ?? "")}
          type="email"
          placeholder="Your Email"
          onKeyUp={(event) => handleInputKeyEvent(event)}
          className="footer-form-input"
        />
        <SubmitBtn
          onClick={handleFormSubmit}
          type="submit"
          className="footer-form-btn"
          disabled={!email}
          sx={{
            alignSelf: { xs: "flex-end" },
          }}
        >
          {/* {"sending" === status ? <RefreshCw className="loader" /> :"Submit"} */}
          Submit
        </SubmitBtn>
      </Box>
      <div className="min-h-42px">
        {"error" === status || error ? (
          <ErrorText
            dangerouslySetInnerHTML={{ __html: error || getMessage(message) }}
          />
        ) : null}
        {"success" === status && "error" !== status && !error && (
          <SuccessText dangerouslySetInnerHTML={{ __html: message }} />
        )}
      </div>
    </>
  );
};

export default NewsletterForm;

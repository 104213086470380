import { useAnalyticsTitle } from "@/hooks/useAnalyticsTitle";
import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import pageContent from "@/constants/pageContent";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import useCarStore from "@/context/carStore";
import AdCarousel from "@/components/ad-caraousel";
import { styled } from "@mui/material/styles";
import {
  HowPricingWorksImage1Icon,
  HowPricingWorksImage2Icon,
  HowPricingWorksImage3Icon,
  HowPricingWorksImage4Icon,
} from "@/assets/images/howpricingworks";

const MainContainer = styled("main")(({ theme }) => ({
  width: "88%",
  maxWidth: "1200px",
  margin: "auto",
  marginTop: "20px",
  marginBottom: "20px",
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
}));

const TopHeaderSection = styled("section")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "2rem",
  margin: "2rem 0px",
  [theme.breakpoints.down("md")]: {
    margin: "0px 0px 2rem 0px",
  },
}));

const Heading = styled("h3")(({ theme }) => ({
  textAlign: "center",
  fontSize: "25.2px",
  margin: "0px",
  lineHeight: "2rem",
}));

const TopParagraph = styled("p")(({ theme }) => ({
  textAlign: "center",
  fontSize: "17.2px",
  fontWeight: 400,
  margin: "0px",
  color: "#000",
  lineHeight: "2rem",
}));

const PointsHeading = styled("h4")(({ theme }) => ({
  textAlign: "center",
  fontSize: "17.2px",
  lineHeight: "2rem",
  margin: "0px",
}));

const AboutSectionContainer = styled("section")(({ theme, reverse }) => ({
  display: "flex",
  // justifyContent: "center",
  gap: "3rem",
  marginBottom: "3rem",
  [theme.breakpoints.down("md")]: {
    flexDirection: reverse ? "column-reverse" : "column",
  },
}));

const TextContainer = styled("div")(({ theme }) => ({
  flex: "62%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "1rem",
}));

const ImageContainer = styled("div")(({ theme }) => ({
  flex: "38%",
}));

const AboutImage1 = styled("img")(({ theme, height }) => ({
  width: "100%",
  height: height ? height : "100%",
  objectFit: "cover",
  // flex: "50%",
  [theme.breakpoints.down("md")]: {
    height: "250px",
  },
}));

const PricingWorks = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { state: stateChoiceFromUrl } = useParams();
  const { resetChart } = useCarStore((store) => store);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (!stateChoiceFromUrl) {
      const persistedStateChoice = localStorage.getItem("state");
      if (persistedStateChoice) {
        resetChart();

        navigate(`/howpricingworks/state/${persistedStateChoice}`);
      }
    }
  }, [location, navigate, resetChart, searchParams, stateChoiceFromUrl]);

  useAnalyticsTitle(pageContent.pricingworks.title);

  return (
    <>
      <Helmet>
        <title>{pageContent.pricingworks.title}</title>
        {pageContent.pricingworks.description && (
          <meta
            name="description"
            content={pageContent.pricingworks.description}
          />
        )}
      </Helmet>

      {stateChoiceFromUrl && (
        <Box mt={"16px"} mb={"16px"}>
          <AdCarousel bannerType="TOP_BANNER" />
        </Box>
      )}

      <MainContainer>
        <TopHeaderSection>
          <Heading>How Australian Automotive Pricing Works</Heading>
          <TopParagraph>
            Australian automotive pricing is made up of a number of various
            manufacturer costs, dealership costs and government taxes. It can
            easily become very confusing to understand exactly what you are
            paying for, especially when having to negotiate with a dealership.
            Luckily we’ve got you covered. Here we take you into the detail and
            breakout the various components that make up pricing and give you
            some insights how to get the best deal.
          </TopParagraph>
        </TopHeaderSection>
        <AboutSectionContainer reverse>
          <TextContainer>
            <TopParagraph>
              The most commonly referred to price is the Drive Away Price which
              is the total price inclusive of all costings and taxes. Simply
              put, this is the manufacturer’s best estimate of the total amount
              you would pay for the car and it’s what we monitor and use at The
              Beep. Australian Consumer Law requires all automotive advertising
              to display this price as to not mislead customers so when you see
              car adverts, online configurators or dealership showroom point of
              sale material, this is the price that should be reflected. Note,
              it may not include any additional services such as scheduled
              servicing, financing or ongoing registration costs.
            </TopParagraph>
            <TopParagraph>
              The drive away price is made up of three main components:
            </TopParagraph>
            <TopParagraph>
              Manufacturer’s Suggested Retail Price + On-Road Costs + Dealer
              Delivery Fees
            </TopParagraph>
          </TextContainer>
          <ImageContainer>
            <AboutImage1
              src={HowPricingWorksImage1Icon}
              alt="How Pricing Works 1"
            />
          </ImageContainer>
        </AboutSectionContainer>
        <AboutSectionContainer>
          <ImageContainer>
            <AboutImage1
              src={HowPricingWorksImage2Icon}
              alt="How Pricing Works 2"
            />
          </ImageContainer>
          <TextContainer>
            <PointsHeading>
              1. Manufacturer’s Suggested Retail Price (MSRP)
            </PointsHeading>
            <TopParagraph>
              This is the vehicle price set by the manufacturer including Goods
              & Services Tax (GST) and any applicable Luxury Car Tax (LCT). GST
              is a broad-based tax of 10% on the sale of most goods and services
              consumed in Australia, including cars.
            </TopParagraph>
            <TopParagraph>
              LCT is a little more complicated. It is charged at 33% of the
              value over certain thresholds set by the Australian Tax Office
              (ATO) every year. The current 2024-25 financial year thresholds
              are defined as $91,387 for vehicles under 7.0L/100km (fuel
              efficient) and $80,567 for vehicles 7.0L/100km and over (non-fuel
              efficient). LCT is then calculated as the Vehicle Price minus the
              LCT threshold divided by 110 multiplied by 33. For example, on a
              $100,000 non-fuel vehicle the LCT would be calculated as $100,000
              - $80,567 / 110 x 33 = $5,830.
            </TopParagraph>
          </TextContainer>
        </AboutSectionContainer>
        <AboutSectionContainer reverse>
          <TextContainer style={{ flex: "66%" }}>
            <PointsHeading>2. On-Road Costs</PointsHeading>
            <TopParagraph>
              These include costs such as vehicle registration, stamp duty and
              compulsory third-party (CTP) insurance. Most of these fees vary
              between states and territories which is why the drive away cost
              itself varies. The Beep uses the default rates set by the
              manufacturers.
            </TopParagraph>
            <TopParagraph>
              Vehicle registration fees are paid when the vehicle is first
              registered to the authorities and then when renewal is required.
              Typically registration is for 12 months on a new vehicle.
            </TopParagraph>
            <TopParagraph>
              Stamp duty is the fee that a State or Territory government applies
              to the process of registering a vehicle in your name and the fee
              itself can vary considerably. For example, a typical $100,000
              vehicle in Victoria would attract $5,200 stamp duty compared to
              NSW which is $4,100. There are numerous stamp duty calculators
              online to assist you.
            </TopParagraph>
            <TopParagraph>
              CTP (also known as Green Slip in some states) is a mandatory
              insurance that covers your liability for any personal injuries
              resulting from a motor accident if you’re at fault. In general it
              covers the driver (you or a different driver), the driver of the
              other vehicle, passengers, pedestrians and cyclists. It does not
              cover any damage to property. It is typically arranged and priced
              into the new vehicle by the dealership at time of purchase.
            </TopParagraph>
          </TextContainer>
          <ImageContainer style={{ flex: "34%" }}>
            <AboutImage1
              src={HowPricingWorksImage3Icon}
              alt="How Pricing Works 3"
              height={"85%"}
            />
          </ImageContainer>
        </AboutSectionContainer>
        <AboutSectionContainer style={{ marginBottom: "0px" }}>
          <ImageContainer>
            <AboutImage1
              src={HowPricingWorksImage4Icon}
              alt="How Pricing Works 4"
            />
          </ImageContainer>
          <TextContainer>
            <PointsHeading>3. Dealer Delivery Fees</PointsHeading>
            <TopParagraph>
              Dealer delivery fees are designed to cover the cost of
              transporting the vehicle to the dealership alongside checking over
              and detailing the vehicle before its final handover. These costs
              can vary from $1,000 up to $8,000 and are generally where
              dealerships hide a lot of their profit margin. If you’re looking
              to negotiate on the vehicle price this is normally a good place to
              start and you may need to prompt the dealership to itemise out
              each specific cost.
            </TopParagraph>
          </TextContainer>
        </AboutSectionContainer>
      </MainContainer>
      {stateChoiceFromUrl && (
        <Box mt={"16px"} mb={"16px"}>
          <AdCarousel bannerType="BOTTOM_BANNER" />
        </Box>
      )}
    </>
  );
};

export default PricingWorks;
